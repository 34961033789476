<template functional>
    <div class="relative bg-red-400 border-red-500 border-4 rounded-lg m-[1px] text-xs text-white z-10 calendar-absence-visibility">
        <p class="absolute left-[1px]" style="top: -2px">
            <fa-icon v-if="props.absence.status === 1" :icon="['fas', 'check-circle']" />
            <fa-icon v-else-if="props.absence.status === 2" :icon="['fas', 'exclamation-circle']" />
            <fa-icon v-else :icon="['fas', 'question-circle']" />
        </p>
        <div class="flex flex-col px-[6px] pt-[1px] pb-[3px]">
            <router-link to="/employees_absence" class="text-center hover:font-semibold">
                {{ props.absence.title }}
            </router-link>
            <p class="text-center">
                {{ props.absence.employee }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        absence: {
            type:    Object,
            default: () => {},
        },
    },
};
</script>
