<template functional>
    <div
        class="draggable relative bg-blue-500 m-[1px] text-xs text-white z-10"
        :style="{'background': props.event.color}"
        :data-event-uuid="props.event.uuid"
        :data-start-date="props.event.start_date"
    >
        <span v-if="props.event.cancelled" class="canceled-bg">
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-24 h-24">
                <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414
                    1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                />
            </svg>
        </span>

        <div class="flex justify-between px-[6px] py-[4px]">
            <div>
                <fa-icon :icon="['fas', 'pencil']" class="cursor-pointer mr-1" @click="listeners.clickEdit(props.event.uuid)" />
                <fa-icon v-if="props.event.confirmation" :icon="['fas', 'minus']" fixed-width />
                <fa-icon v-else :icon="['fas', 'check']" fixed-width />
                <fa-icon v-if="props.event.note" :icon="['fas', 'info']" fixed-width />
                <fa-icon v-if="props.event.keys" :icon="['fas', 'key']" fixed-width />
            </div>
            <p>
                {{ props.event.start_time }}<span class="mx-[2px]">-</span>{{ props.event.end_time }}
            </p>
        </div>
        <div class="bg-white bg-opacity-20 pt-[3px] pb-[6px] px-[3px]">
            <router-link :to="`/clients/${props.event.client_uuid}/dashboard`" class="hover:font-semibold calendar-client-visibility">
                <fa-icon :icon="['fas', 'user-circle']" fixed-width />
                {{ props.event.title }}
            </router-link>
            <p class="my-[2px] calendar-address-visibility">
                <fa-icon :icon="['fas', 'map-marker']" fixed-width />
                {{ props.event.street }}
            </p>
            <p class="calendar-city-visibility">
                <fa-icon :icon="['fas', 'map-marker']" fixed-width />
                {{ props.event.city }}
            </p>
        </div>

        <div v-if="props.event.employees.length > 0" class="flex flex-col pt-[3px] pb-[3px] px-[3px] calendar-employee-visibility">
            <router-link
                v-for="employee in props.event.employees"
                :key="employee.uuid + ' ' + props.event.uuid"
                class="mb-[2px] hover:font-semibold"
                :to="`/employees/${employee.uuid}/information`"
            >
                <fa-icon :icon="['fas', 'user']" fixed-width />
                {{ employee.name }}
            </router-link>
        </div>

        <div class="flex flex-col pt-[3px] pb-[4px] px-[6px] mt-[1px] bg-white bg-opacity-20 calendar-services-visibility">
            <template v-if="props.userCanReadServices">
                <router-link
                    v-for="service in props.event.services"
                    :key="service"
                    to="/services"
                    class="mb-[2px] hover:font-semibold text-center"
                >
                    {{ service }}
                </router-link>
            </template>
            <template v-else>
                <p
                    v-for="service in props.event.services"
                    :key="service"
                    class="mb-[2px] hover:font-semibold text-center"
                >
                    {{ service }}
                </p>
            </template>
        </div>

        <p v-if="props.event.note" class="pt-1 pb-2 px-2 calendar-note-visibility" v-html="props.event.note" />
    </div>
</template>
<script>
export default {
    props: {
        event: {
            type:    Object,
            default: () => {},
        },
        userCanReadServices: {
            type:    Boolean,
            default: true,
        },
    },
};
</script>
