<template>
    <table class="w-full relative">
        <thead class="table-headers border border-gray-200 text-sm">
            <th class="border-r font-light">
                {{ $t('calendar.mon') }}
            </th>
            <th class="border-r font-light">
                {{ $t('calendar.thu') }}
            </th>
            <th class="border-r font-light">
                {{ $t('calendar.wed') }}
            </th>
            <th class="border-r font-light">
                {{ $t('calendar.thu') }}
            </th>
            <th class="border-r font-light">
                {{ $t('calendar.fri') }}
            </th>
            <th class="border-r font-light">
                {{ $t('calendar.sat') }}
            </th>
            <th class="border-r font-light">
                {{ $t('calendar.sun') }}
            </th>
        </thead>
        <tbody v-drag-and-drop:options="dragAndDropOptions">
            <!-- Render weeks -->
            <tr v-for="(week, key) in eventsData" :key="key" class="div-week ">
                <!-- Render days -->
                <td
                    v-for="(day, keyDay) in week"
                    :key="keyDay"
                    class="drop-zone-wrapper relative align-top border border-gray-200 group h-40"
                    width="14.28%"
                    :data-date="Object.keys(day)[0]"
                    @added="dragEnded"
                >
                    <div class="drop-zone hidden absolute p-[2px] top-8 w-1 left-0 z-0">
                        <div class="w-full h-full bg-white border-dashed border-2 border-blue-500" />
                    </div>

                    <!-- Render day content -->
                    <template v-for="(events, date) in day">
                        <div :key="date" class="flex p-1 justify-between sticky top-[-1px] z-20 bg-white opacity-70">
                            <p class="week-number">
                                {{ $t('calendar.w') }}.{{ $dayjs(date, 'DD.MM.YYYY').week() }}
                            </p>
                            <!-- AddEvent button -->
                            <div v-if="userCan('create calendar events')" class="absolute" style="top: 5px; right: 2px;">
                                <div
                                    class="flex justify-center opacity-0 group-hover:opacity-100 cursor-pointer transition text-white rounded-full bg-red-400"
                                    @click="showAddModal(date)"
                                >
                                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </div>
                            </div>
                            <p>
                                {{ $dayjs(date, 'DD.MM.YYYY').format('D') }}
                            </p>
                        </div>

                        <template v-for="absence in filterAbsenceByDate(date, absenceData)">
                            <AbsenceContent :key="absence.employee_uuid" :absence="absence" />
                        </template>

                        <template v-for="event in events">
                            <EventContent :key="event.uuid" :event="event" :userCanReadServices="userCan('read services')" @clickEdit="showEditModal" />
                        </template>

                        <div v-if="redDaysData[date]" :key="redDaysData[date]" class="absolute z-0 top-0 left-0 w-full h-full bg-red-300">
                            <p class="text-black text-sm text-center mt-[6px]">
                                {{ redDaysData[date] }}
                            </p>
                        </div>
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import Vue from 'vue';
import VueDraggable from 'vue-draggable';
import EventContent from './EventContent.vue';
import AbsenceContent from './AbsenceContent.vue';

Vue.use(VueDraggable);

export default {
    components: {
        EventContent,
        AbsenceContent,
    },

    props: {
        eventsData: {
            type:    Array,
            default: () => [],
        },
        redDaysData: {
            type:    Object,
            default: () => {},
        },
        absenceData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            dragAndDropOptions: {
                dropzoneSelector:  '.drop-zone-wrapper',
                draggableSelector: '.draggable',
                showDropzoneAreas: true,
            },
        };
    },

    methods: {
        dragEnded(event) {
            const uuid = event.detail.items[0].getAttribute('data-event-uuid');
            const oldDate = event.detail.items[0].getAttribute('data-start-date');
            const newDate = event.target.getAttribute('data-date');
            this.$emit('eventDroppedToNewDate', uuid, oldDate, newDate);
        },

        showAddModal(date) {
            this.$emit('dateClicked', date);
        },

        showEditModal(uuid) {
            this.$emit('showEditModal', uuid);
        },

        filterAbsenceByDate(date, absenceData) {
            const localDate = this.$dayjs(date, 'DD.MM.YYYY');

            return absenceData.filter(absence => {
                const start = this.$dayjs(absence.start, 'YYYY-MM-DD HH:mm');
                const end = this.$dayjs(absence.end, 'YYYY-MM-DD HH:mm');
                return localDate.isBetween(start, end, 'day', '[]');
            });
        },
    },
};
</script>
<style scoped>
@import './NewMonthView.scss';

tr td:not(:first-child) .week-number {
    opacity: 0;
}
</style>
<style>
    .drop-zone-wrapper[aria-dropeffect="move"] .drop-zone {
        display: flex !important;
        z-index: 20;
        height:calc(100% - 33px);
        width: 100%;
        opacity: .7;
    }
</style>
